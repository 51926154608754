<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form ref="guide" :model="guide" @submit.prevent.native="saveFn">
      <el-form-item label="Блюдо">
        <el-autocomplete
          v-model="guide.title"
          style="width: 100%"
          :fetch-suggestions="searchDishFn"
          :debounce="600"
          prefix-icon="el-icon-search"
          placeholder="Поиск блюда"
          value-key="title"
          @select="selectDishFn"
        />
      </el-form-item>
      <el-form-item label="Филиал">
        <el-autocomplete
          v-model="guide.branch"
          style="width: 100%"
          :fetch-suggestions="searchBranchFn"
          :debounce="600"
          prefix-icon="el-icon-search"
          placeholder="Поиск филиала"
          value-key="title"
          @select="selectBranchFn"
        />
        <span v-if="error" class="has-text-danger">Блюдо в этом филиале не найдено</span>
      </el-form-item>
      <el-form-item label="Цена">
        <el-input-number
          v-model="guide.price"
          style="width: 100%"
          :precision="2"
          :step="0.1"
          :min="0"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="action === 'edit'" type="primary" @click="copyElement">
        Сделать копию
      </el-button>
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="success" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_DISHES } from '@/app/settings/dishes/shared/state/types/action_types';
import { DATA as DATA_DISHES } from '@/app/settings/dishes/shared/state/types/getter_types';

import { FETCH_BRANCHES } from '@/app/settings/branches/shared/state/types/action_types';
import { DATA as DATA_BRANCHES } from '@/app/settings/branches/shared/state/types/getter_types';
import {
  STORE_GUIDE,
  SELECT_GUIDE_RESET,
  FETCH_GUIDE,
  UPDATE_GUIDE,
} from '../shared/state/types/action_types';
import { LOADING, SELECTED, ERROR } from '../shared/state/types/getter_types';

import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'GuideModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить блюдо',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      dish: { title: null },
      price: null,
      branch: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({
      loading: LOADING,
      guide: SELECTED,
      error: ERROR,
      dishes: DATA_DISHES,
      branches: DATA_BRANCHES,
    }),
  },
  methods: {
    ...mapActions({
      store: STORE_GUIDE,
      fetch: FETCH_GUIDE,
      fetchDishes: FETCH_DISHES,
      reset: SELECT_GUIDE_RESET,
      fetchBranches: FETCH_BRANCHES,
      update: UPDATE_GUIDE,
    }),
    closeModal() {
      this.visible = false;
      this.reset();
    },
    async saveFn() {
      switch (this.action) {
        case 'edit':
          await this.update(this.guide);
          if (this.error) {
            this.showErrorMessage('Ошибка обновления');
          }
          this.showSuccessMessage('Успешно обновлен');
          this.closeModal();
          break;
        default:
          await this.store(this.guide);
          if (this.error) {
            this.showErrorMessage('Ошибка добавления');
          }
          this.showSuccessMessage('Успешно добавлен');
          this.closeModal();
          break;
      }
    },
    async searchDishFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchDishes(filter);
      cb(this.dishes.data);
    },
    async searchBranchFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchBranches(filter);
      cb(this.branches.data);
    },
    selectDishFn(item) {
      Object.assign(this.guide, { dish_id: item.id });
    },
    async selectBranchFn(item) {
      if (this.action === 'edit') {
        await this.fetch({ branch: item.id });
      }
      Object.assign(this.guide, { branch_id: item.id });
    },
    async copyElement() {
      await this.store(this.guide);
    },
  },
};
</script>
