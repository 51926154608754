<template>
  <div class="guides">
    <guide-filter />
    <guide-table />
  </div>
</template>

<script>
import GuideFilter from './guide-filter/guide-filter.vue';
import GuideTable from './guide-table/guide-table.vue';

export default {
  name: 'Guide',
  components: { GuideTable, GuideFilter },
};
</script>

<style scoped></style>
