<template>
  <div class="guides-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="tableRules"
      @reload="changePage"
      @edit="handleEdit"
      @delete="handleDelete"
    />
    <guide-modal :show.sync="modal.show" :title="modal.title" action="edit" />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING, DATA, ERROR } from '../shared/state/types/getter_types';
import { FETCH_GUIDES, SELECT_GUIDE, DELETE_GUIDE } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';
import GuideModal from '../guide-modal/guide-modal.vue';

export default {
  name: 'GuideTable',
  components: { AppTable, GuideModal },
  mixins: [notifyMixin],
  data() {
    return {
      modal: {
        show: false,
        title: null,
      },
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
      error: ERROR,
    }),
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
    tableRules() {
      if (this.isAvailable) {
        return [
          {
            value: 'edit',
            type: 'primary',
            icon: 'el-icon-edit',
          },
          {
            value: 'delete',
            type: 'danger',
            icon: 'el-icon-delete',
          },
        ];
      }
      return [];
    },
    fields() {
      if (this.isAvailable) {
        return [
          { label: '#', value: 'id', width: 70 },
          { label: 'Название', value: 'dish.title', width: 300 },
          { label: 'Категория', value: 'dish.meal.title', width: 150 },
          { label: 'Цена', value: 'price' },
          { label: 'Себестоимость', value: 'price_custom', width: 150 },
          { label: 'Филиал', value: 'branch.title', width: 100 },
        ];
      }
      return [
        { label: '#', value: 'id' },
        { label: 'Название', value: 'dish.title' },
        { label: 'Категория', value: 'dish.meal.title' },
        { label: 'Цена', value: 'price' },
        { label: 'Себестоимость', value: 'price_custom' },
        { label: 'Филиал', value: 'branch.title' },
      ];
    },
  },
  watch: {
    async page(page) {
      await this.fetch({ page, filter: this.$route.query });
    },
    async $route(to) {
      await this.fetch({ page: this.page, filter: to.query });
    },
  },
  async mounted() {
    const queryParams = this.$route.query;
    await this.fetch({ page: this.page, filter: queryParams });
  },
  methods: {
    ...mapActions({ fetch: FETCH_GUIDES, select: SELECT_GUIDE, delete: DELETE_GUIDE }),
    changePage(page) {
      this.page = page;
    },
    async handleEdit(guide) {
      await this.select({
        id: guide.id,
        dish_id: guide.dish.id,
        title: guide.dish.title,
        branch: guide.branch ? guide.branch.title : null,
        price: guide.price,
      });
      this.modal.show = true;
      this.modal.title = 'Редактирование блюда';
    },
    handleDelete(guide) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(guide.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Блюдо успешно удален');
        })
        .catch(() => {});
    },
  },
};
</script>
