<template>
  <div class="guide-filter">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-badge is-dot :hidden="isActiveFilter" class="item">
          <el-button icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
        </el-badge>
      </el-col>
      <el-col v-if="isAvailable" :span="12">
        <div class="is-flex is-justify-content-flex-end">
          <el-button type="info" icon="el-icon-refresh-right" @click="calculationCostPrice">
            Пересчитать себестоимость
          </el-button>
          <el-button type="primary" icon="el-icon-plus" @click="showModal = true">
            Добавить ингредиент
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div v-show="isShowFilter" class="filter-panel mt-4">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input v-model="title" prefix-icon="el-icon-search" placeholder="Поиск по названию" />
        </el-col>
        <el-col :span="8">
          <el-autocomplete
            v-model="branch"
            style="width: 100%"
            :fetch-suggestions="searchBranchFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Поиск по филиалу"
            value-key="title"
          />
        </el-col>
        <el-col :span="8">
          <el-autocomplete
            v-model="category"
            style="width: 100%"
            :fetch-suggestions="searchCategoryFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Поиск по категории"
            value-key="title"
          />
        </el-col>
      </el-row>
    </div>
    <guide-modal :show.sync="showModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { FETCH_BRANCHES } from '@/app/settings/branches/shared/state/types/action_types';
import { DATA as DATA_BRANCHES } from '@/app/settings/branches/shared/state/types/getter_types';
import { FETCH_MEALS } from '@/app/settings/meals/shared/state/types/action_types';
import { DATA as DATA_MEALS } from '@/app/settings/meals/shared/state/types/getter_types';
import { FETCH_GUIDES, CALCULATE_COST_PRICE } from '../shared/state/types/action_types';
import guideModal from '../guide-modal/guide-modal.vue';

export default {
  name: 'GuideFilter',
  components: { guideModal },
  data() {
    return {
      isActiveFilter: true,
      isShowFilter: false,
      branch: null,
      category: null,
      title: null,
      showModal: false,
      query: {},
    };
  },
  computed: {
    ...mapGetters({ branches: DATA_BRANCHES, meals: DATA_MEALS }),
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
  },
  watch: {
    query(query) {
      if (query['dish.title'] === '') {
        delete query.title;
      }
      if (query['dish.meal.title'] === '') {
        delete query['dish.meal.title'];
      }
      if (query['branch.title'] === '') {
        delete query['branch.title'];
      }
      this.$router.push({ path: this.path, query }).catch(() => {});
    },
    title(value) {
      this.query = { ...this.query, 'dish.title': value };
    },
    category(value) {
      this.query = { ...this.query, 'dish.meal.title': value };
    },
    branch(value) {
      this.query = { ...this.query, 'branch.title': value };
    },
  },
  created() {
    const queryParams = this.$route.query;
    if (queryParams['dish.title']) {
      this.title = queryParams.title;
      this.openFilter();
    }
    if (queryParams['dish.meals.title']) {
      this.category = queryParams['dish.meals.title'];
      this.openFilter();
    }
    if (queryParams['branch.title']) {
      this.category = queryParams['dish.meals.title'];
      this.openFilter();
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_GUIDES,
      fetchBranches: FETCH_BRANCHES,
      fetchMeals: FETCH_MEALS,
      calculateCostPrice: CALCULATE_COST_PRICE,
    }),
    openFilter() {
      this.isShowFilter = !this.isShowFilter;
      this.isActiveFilter = !this.isActiveFilter;
    },
    async searchBranchFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchBranches(filter);
      cb(this.branches.data);
    },
    async searchCategoryFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchMeals(filter);
      cb(this.meals.data);
    },
    async calculationCostPrice() {
      await this.calculateCostPrice();
    },
  },
};
</script>
